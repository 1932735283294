import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ["ewc"]

  updateEwcCode(e) {
    this.selectize.clear()
    if(e.target.value == "non_waste") {
      this.selectize.disable()
      return
    }

    this.selectize.clearOptions()

    fetch(`/ewc_codes?parameter_type=${e.target.value}`)
      .then(response => response.json())
      .then(data => {
        data.forEach(ewc => {
          this.selectize.addOption({
            value: ewc.id,
            text: ewc.full_name
          })
        })
        this.selectize.enable()
      })
  }

  connect() {

    const select = this.ewcTarget.querySelector("select")
    const placeholder = select.getAttribute("placeholder") || "Select..."

    const plugins = ['dropdown_input']

    const defaultOptions = {
      placeholder: placeholder,
      hidePlaceholder: true,
      maxItems: 1,
      plugins: plugins,
      maxOptions: null,
      onItemRemove: function(values) {
        this.input.value = ""
      }
    }

    this.selectize = new TomSelect(select, defaultOptions)

    if(select.querySelectorAll("option").length < 2) {
      this.selectize.disable()
    }

  }
  

}