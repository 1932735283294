import tom_select_controller from "./tom_select_controller"

export default class extends tom_select_controller {

  renderFunction(data, escape) {
    let html = `<div class="tt-dataset-parameter">
      <div class="tt-dataset-parameter__name">${escape(data.name)}</div>`

    if (data.ewc_code) {
      html += `<div class="tt-dataset-parameter__ewc_code">${escape(data.ewc_code)}</div>`
    }

    return html + `</div>`
  }

  options() {
    return {
      options: this.optionsValue,
      valueField: "id",
      labelField: "name",
      render: {
        item: this.renderFunction,
        option: this.renderFunction
      }
    }
  }


}
